import React, { useState } from "react"
import { getStripe } from '../../services/stripe'
import styled from "styled-components"

const Checkout = ({isNav = false}) => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1IW0aCGLfvOqbhx489vsCb7W", quantity: 1 }],
      successUrl: `https://www.magicalimperfection.com`,
      cancelUrl: `https://www.magicalimperfection.com`,
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  return isNav
    ? <NavButton
        disabled={loading}
        href="https://buy.stripe.com/28o3eheC6cMr1ck8wy">
        Purchase
    </NavButton>
    : <Button
        disabled={loading}
        href="https://buy.stripe.com/28o3eheC6cMr1ck8wy">
        BUY THE DVD
      </Button>
}

const Button = styled.a`
   transition: all 0.5s;
  background-color: ${props => props.theme.color.accent};
  padding: 12px 60px;
  box-shadow: 2px 5px 10px rgba(0,0,0,.1);
  border-radius: 6px;
  letter-spacing: 1.5px;
  display: block;
  width: 250px;
  text-align: center;
  margin: 20px auto 0;
  
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.accent};
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const NavButton = styled.a`
     transition: all 0.15s;
     color: ${props => props.theme.color.primary};
  // background-color: ${props => props.theme.color.accent};
  //
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.accent};
  }
`

export default Checkout
