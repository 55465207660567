import React from "react"
import styled from "styled-components"

import { Container } from "../global"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(sourceInstanceName: { eq: "product" }, name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper id="inquiries">
      <Subtitle>Inquiries</Subtitle>
      <FooterColumnContainer>
        <FooterColumn>
          <span>Scott Calbeck</span>
          <ul>
            <li><a href="mailto:scottcalbeck@sympatico.ca" target="_blank" rel="noreferrer" >Email</a></li>
            <li>416-452-7800</li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>David Hoffert</span>
          <ul>
            <li><a href="mailto:davidpop@rogers.com" target="_blank" rel="noreferrer" >Email</a></li>
            <li><a href="http://davidhoffert.weebly.com/" target="_blank" rel="noreferrer" >Website</a></li>
          </ul>
        </FooterColumn>
      </FooterColumnContainer>
      <ImageWrapper>
        <StyledImage loading='eager' fluid={data.logo.childImageSharp.fluid} />
        <p>&#169; 2020 Smoke Lake Productions, Inc.</p>
      </ImageWrapper>
      {/*<Logo>*/}
      {/*</Logo>*/}
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.background.light};
  margin: 80px 0 0;
  padding: 0 0 80px;
  
  @media (max-width: ${props => props.theme.screen.xs}) {
    margin: 0;
  }
`

// const Logo = styled.div`
//   font-family: ${props => props.theme.font.extrabold};
//   ${props => props.theme.font_size.regular};
//   color: ${props => props.theme.color.black.regular};
//   text-decoration: none;
//   letter-spacing: 1px;
//   margin: 0;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   z-index: 9;
//   text-decoration: none;
//   outline: 0px;
// `

const Subtitle = styled.h5`
  font-size: 26px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 32px;
  padding-top: 60px;
  text-align: center;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 16px;
  }
`

const FooterColumnContainer = styled(Container)`
  display: flex;
  max-width: 500px;
  justify-content: space-around;
  @media (max-width: ${props => props.theme.screen.sm}) {
    //flex-direction: column;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const FooterColumn = styled.div`
  span {
    font-size: 18px;
    font-family: ${props => props.theme.font.regular};
    color: ${props => props.theme.color.primary};
    border-bottom: 1px solid ${props => props.theme.color.accent};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.regular};
      font-size: 18px;
      
      a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        transition: .15s;

        &:hover {
          color: ${props => props.theme.color.accent}
        }
      }
    }
  }
`

const StyledImage = styled(Img)`
  width: 300px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`

export default Footer
