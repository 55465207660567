import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
// import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import Laurels from '../components/sections/laurels'
// import GetStarted from "../components/sections/getstarted"
// import Trailer from '../components/sections/trailer'
import About from '../components/sections/about'
import Team from '../components/sections/team'

const IndexPage = () => (
  <Layout>
    <SEO title='Magical Imperfection' />
    <Navigation />
    <Header />
    <Laurels/>
    <Team/>
    <About />
    {/*<Features/>*/}
    {/*<GetStarted />*/}
    {/*<Trailer/>*/}
    <Footer />
  </Layout>
)

export default IndexPage
