import React from "react"
import styled from "styled-components"
import {
  graphql,
  useStaticQuery,
  // Link
} from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import Checkout from "../sections/checkout"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "poster" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // const handleSubmit = event => {
  //   event.preventDefault()
  // }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <Subtitle>
            <TitleSpan>Magical Imperfection</TitleSpan> tells the inspirational
            story of world-renowned Canadian architect Raymond Moriyama.
            Moriyama’s acclaimed architectural achievements, including the
            Ontario Science Centre and the Canadian War Museum, draw upon his
            life experiences, including the trauma of wartime internment as a
            Japanese Canadian. Imprisoned in his own country during the 1940s
            because of his race, Ray found the strength to combat injustice by
            devoting his career to social justice and equality.
            <Checkout/>
          </Subtitle>
          <ImageWrapper>
            <StyledImage
              loading="eager"
              fluid={data.file.childImageSharp.fluid}
            />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.background.light};
  padding: 120px 0 80px 0;
  position: relative;
  //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  margin-bottom: 16px;
  padding-right: 40px;
  line-height: 20px;
`

const TitleSpan = styled.span`
  color: ${props => props.theme.color.accent};
`

const Flex = styled.div`
  //display: grid;
  //justify-content: space-between;
  //align-content: center;
  //grid-template-columns: 1fr 1fr;
  display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column-reverse;
  }
`

// const HeaderForm = styled.form`
//   display: flex;
//   flex-direction: row;
//   padding-bottom: 16px;
//
//   @media (max-width: ${props => props.theme.screen.sm}) {
//     flex-direction: column;
//   }
// `
//
// const FormSubtitle = styled.span`
//   ${props => props.theme.font_size.xxsmall}
// `
//
// const FormSubtitleLink = styled(Link)`
//   color: ${props => props.theme.color.secondary};
//   padding-bottom: 1px;
//   margin-left: 8px;
//   text-decoration: none;
//   border-bottom: 1px solid ${props => props.theme.color.secondary};
// `
//
// const HeaderInput = styled.input`
//   font-weight: 500;
//   font-size: 16px;
//   color: ${props => props.theme.color.primary};
//   line-height: 42px;
//   width: 100%;
//   text-align: left;
//   height: 60px;
//   border-width: 1px;
//   border-style: solid;
//   border-color: ${props => props.theme.color.secondary};
//   border-image: initial;
//   border-radius: 4px;
//   padding: 8px 16px;
//   outline: 0px;
//   &:focus {
//     box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
//   }
//   @media (max-width: ${props => props.theme.screen.md}) {
//     margin-bottom: 8px;
//   }
//   @media (max-width: ${props => props.theme.screen.sm}) {
//     display: block;
//     width: 100%;
//   }
// `
//
// const HeaderButton = styled.button`
//   font-weight: 500;
//   font-size: 14px;
//   color: white;
//   letter-spacing: 1px;
//   height: 60px;
//   display: block;
//   margin-left: 8px;
//   text-transform: uppercase;
//   cursor: pointer;
//   white-space: nowrap;
//   background: ${props => props.theme.color.secondary};
//   border-radius: 4px;
//   padding: 0px 40px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   outline: 0px;
//   &:hover {
//     box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
//   }
//   @media (max-width: ${props => props.theme.screen.md}) {
//   }
//   @media (max-width: ${props => props.theme.screen.sm}) {
//     margin-left: 0;
//   }
// `
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`
