import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      reference: file(sourceInstanceName: { eq: "product" }, name: { eq: "reference" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ray: file(sourceInstanceName: { eq: "product" }, name: { eq: "ray-web" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="about the film">
      <Container>
        <Subtitle>About the Film</Subtitle>
        <Flex>
          <ImageWrapper>
            <StyledImage fluid={data.ray.childImageSharp.fluid}/>
          </ImageWrapper>
          <HeaderTextGroupReverse>
            <h2>
              Magical Imperfection tells the inspirational story of world-renowned Canadian architect Raymond Moriyama.  Imprisoned in his own country during the 1940s because of his race, Ray found the strength to overcome injustice and prejudice on his journey to artistic excellence.  His groundbreaking works, including the Ontario Science Centre, the Canadian War Museum and the Canadian Embassy in Tokyo, are used by thousands of people every day, and each building reflects Ray’s unshakable belief in the need for inclusion and social justice in our world.
            </h2>

            <h2>
              Ray tells his story through a series of interviews at the age of eighty-five, reflecting back on a life filled with both triumph and tragedy.  Born in Vancouver to Japanese immigrants in 1929, at age four he suffered severe burns and was confined to a bed for eight months. During this recovery period he would stare out the window each day and marvel at the house being built across the street.  At this tender young age, Ray decided to be an architect.
            </h2>
            <h2>
              It was a dream that was threatened by the Japanese attack on Pearl Harbor in 1941.  Within months, 22,000 Japanese Canadians were stripped of their homes and businesses and sent to internment camps. Twelve year old Ray, his mother and two younger sisters were sent to a camp outside Slocan, B.C., while his father was made a prisoner of war.  This loss of freedom would haunt Moriyama for the rest of his life, and inform every aspect of his private and professional life.
            </h2>
          </HeaderTextGroupReverse>
        </Flex>
        <FlexReverse>
          <HeaderTextGroup>
            <h2>
              During his years in the camp, young Ray would often slip out to a secret hideaway on the Slocan River.  There, he built a tree house where he could keep a lookout for police.  It was his first adventure in architecture - a place of refuge where he began to appreciate the beauty of nature and contemplate a time when he could make a contribution to the world.  Through talent, perseverance and the kindness of mentors, he was able to fulfill and surpass his loftiest ambitions.
            </h2>
            <h2>
              The documentary examines Moriyama’s humanistic and philosophical journey by focusing on several of his most notable buildings.  In the 1950s, his design of the Japanese Canadian Cultural Centre helped a community regain their standing and dignity in society.  Under his stewardship, the Ontario Science Centre broke the mould to become the first hands-on science museum in the world.  The Scarborough Civic Centre gave Ray the opportunity to design a building that truly was for the people.  And the Canadian Embassy in Tokyo allowed Ray to extend a hand to his ancestors.
            </h2>
            <h2>
              Perhaps his crowning achievement is the Canadian War Museum in Ottawa, inspired by his own wartime experience.  The striking architecture uses non-symmetrical design elements to challenge the public into thinking about war and peace, and to express a feeling of hope that he believes is still unfulfilled in all of us.  It is the embodiment of the credo passed down to him from his grandfather – the pursuit of “perfect imperfection.”
            </h2>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.reference.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </FlexReverse>
        <Lessertitle>Selected Buildings by Moriyama &amp; Teshima Architects</Lessertitle>
        <ColumnContainer>
          <Column>
            <ul>
              <li>Halfway House (1958)</li>
              <li>Crothers Used Equipment Centre (1959)</li>
              <li>Edwards Gardens Pavilion (1963)</li>
              <li>Japanese Canadian Cultural Centre 1963</li>
              <li>Ontario Science Centre (1969)</li>
              <li>Burnhamthorpe District Library (1976)</li>
              <li>Toronto Reference Library (1977)</li>
              <li>Whitby Municipal Centre (1977)</li>
              <li>Brock University Science Complex (1983)</li>
              <li>Scarborough Civic Centre (1984)</li>
              <li>Science North (1984)</li>
              <li>Place St. Charles (1985)</li>
              <li>Chemical Plaza (1986)</li>
              <li>North York Central Library (1987)</li>
              <li>Bay Bloor Radio (1988)</li>
              <li>North York Civic Centre (1989)</li>
              <li>Ottawa City Hall (1990)</li>
              <li>Canadian Embassy in Tokyo (1991)</li>
              <li>York University Vari Hall (1992)</li>
            </ul>
          </Column>
          <Column>
            <ul>
              <li>Bank of Montreal Institute for Learning (1993)</li>
              <li>University of Windsor Student Centre (1993)</li>
              <li>Bata Shoe Museum (1995)</li>
              <li>Ontario Ministry of Natural Resources Centre (1996)</li>
              <li>Peace Bridge Commercial Centre (1996)</li>
              <li>Casino Rama (1996)</li>
              <li>River Run Centre (1997)</li>
              <li>Seneca College at York University (1999)</li>
              <li>National Museum of Saudi Arabia (1999)</li>
              <li>Havergal College Upper and Junior Schools (1999)</li>
              <li>Teknion Head Office Addition (1999)</li>
              <li>Global Group Centre (2000)</li>
              <li>Brock University Alan Earp Residence (2001)</li>
              <li>Brock University Walker Health and Fitness Complex (2002)</li>
              <li>McMaster University Student Centre (2002)</li>
              <li>TEL Building (2003)</li>
              <li>Ryerson University School of Engineering (2004)</li>
              <li>Toronto French School (2004)</li>
              <li>University of Toronto School of Continuing Studies (2004)</li>
              <li>Canadian War Museum (2005)</li>
            </ul>
          </Column>
        </ColumnContainer>
      </Container>
    </HeaderWrapper>
  )
}

export default About

const HeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 100px 0 80px 0;
  position: relative;
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 40px 0;
  }
`
const Subtitle = styled.h5`
  font-size: 28px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 32px;
  text-align: center;
`

const Lessertitle = styled.h5`
  font-size: 22px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin: 54px 0 32px;
  text-align: center;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 32px 0;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;
  padding-right: 32px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.small}
    line-height: 22px;
  }

  p {
    margin-bottom: 48px;
  }
`

const HeaderTextGroupReverse = styled.div`
  margin: 0;
  padding-left: 32px;
  
  @media (max-width: ${props => props.theme.screen.md}) {
      padding-left: 0;
    }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.small}
    line-height: 22px;
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
  }
`

const FlexReverse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column-reverse;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    margin: 24px 0;
  }
`

const StyledImage = styled(Img)`
  width: 475px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`

const ColumnContainer = styled(Container)`
  display: flex;
  max-width: 500px;
  justify-content: space-around;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const Column = styled.div`
  span {
    font-size: 18px;
    font-family: ${props => props.theme.font.regular};
    color: ${props => props.theme.color.primary};
    border-bottom: 1px solid ${props => props.theme.color.accent};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.regular};
      font-weight: bold;
      //font-size: 18px;
      ${props => props.theme.font_size.small}

      a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        transition: .15s;

        &:hover {
          color: ${props => props.theme.color.accent}
        }
      }
    }
  }
`
