import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import Header from "./header"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      team: file(sourceInstanceName: { eq: "product" }, name: { eq: "team" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledSection id="the filmmakers">
      <StyledContainer>
        <Subtitle>The Filmmakers</Subtitle>
        <ImageWrapper>
          <StyledImage fluid={data.team.childImageSharp.fluid}/>
        </ImageWrapper>
        <HeaderTextGroup>
          <h2><TitleSpan>Scott Calbeck</TitleSpan> (Director/Producer) is a director and producer of documentary films and a historian. After studying journalism and history at Indiana University and Carleton University, Calbeck earned a master’s degree in public history from the University of Western Ontario.  <TitleSpan>Magical Imperfection</TitleSpan> is his first film as director.</h2>
          <h2>Calbeck’s films cover a range of subjects, but reflect his interests both in exploring history and highlighting social justice issues.  <TitleSpan>Raw Opium</TitleSpan>, a study of the world trade in opium and heroin, grew out of a proposal to link Afghan poppy farmers to the legal pharmaceutical industry.  For his role as researcher, Calbeck was nominated for a Canadian Screen Award.  <TitleSpan>Pauline: The Pauline Johnson Story</TitleSpan> explored the life of the internationally-renowned poet and performer, who in the late 19th and early 20th centuries was one of the first voices for native rights and women’s rights.  <TitleSpan>Pauline</TitleSpan> won Best Story at the 1999 American Indian Film Festival.  <TitleSpan>Brainstorm</TitleSpan> featured a neurosurgeon inspired by the medical inquiries of Leonardo da Vinci, where science and art in the 15th and 20th centuries are at the heart of an emotional journey of healing and hope.  <TitleSpan>Lacrosse: The Creator’s Game</TitleSpan>, an examination of the native origins of lacrosse and its cultural significance, showcased native North Americans and their connections to a game first played 500 years ago.  <TitleSpan>Lacrosse</TitleSpan> won Best Documentary Short at the 1995 American Indian Film Festival.</h2>
        </HeaderTextGroup>
        <Divider/>
        <HeaderTextGroup>
          <h2><TitleSpan>David Hoffert</TitleSpan> (Producer/Writer/Editor) has won six international awards for directing the NFB feature documentary <TitleSpan>OCD: The War Inside</TitleSpan>, and another award for producing <TitleSpan>Magical Imperfection</TitleSpan>, about the renowned architect Raymond Moriyama, whose childhood spent in a Japanese Internment camp led to a lifetime fight for social justice.  Both films have screened in film festivals around the world and have been broadcast on PBS, TVO, Documentary Channel, etc.  He also produced and directed the upcoming feature documentary <TitleSpan>Rhapsody</TitleSpan>, about a 99-year-old Holocaust survivor whose captivating story is told through his long lost musical masterpiece and secret wartime diaries.</h2>
          <h2>Hoffert has directed over two hundred episodes of over a dozen television series, including <TitleSpan>Canada Sings, American Mystery, Yukon Gold, Style by Jury, Party Mamas, Designer Guys and The Transgender Project</TitleSpan>.  Along the way he has been nominated for three Gemini Awards (now called the Canadian Screen Awards), including Best Director of a Reality Series.</h2>
          <h2>In addition, Hoffert produced and directed the travel adventure web series <TitleSpan>Where 2 Next</TitleSpan>, created a viral video with a million hits, composed musical scores for several television series and theatrical productions (resulting in a Dora Award nomination for Best Music), and produced the first recordings of the Beastie Boys.  Full résumé and clips can be found at <a
            href="http://davidhoffert.weebly.com" target="_blank" rel="noreferrer" >davidhoffert.weebly.com</a></h2>
        </HeaderTextGroup>
      </StyledContainer>
    </StyledSection>
  )
}

export default Team

const StyledContainer = styled(Container)``
const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
`

const HeaderTextGroup = styled.div`
  margin: 0;
  padding-right: 32px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.color.accent};
    transition: .15s;
    
    &:hover {
      color: black;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.small}
    line-height: 22px;
  }

  p {
    margin-bottom: 48px;
  }
`

const Divider = styled.div`
  margin: 32px auto;
  border-top: 1px solid ${props => props.theme.color.black.light};
  max-width: 300px;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    opacity: 0;
  }
`

const TitleSpan = styled.span`
  color: ${props => props.theme.color.accent};
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 450px;
  margin: 16px 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 300px;
  }
`

const Subtitle = styled.h5`
  font-size: 26px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 32px;
  text-align: center;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 8px;
  }
`
