import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Laurels = () => {
  const data = useStaticQuery(graphql`
    query {
      faff: file(sourceInstanceName: { eq: "product" }, name: { eq: "faff" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      newYork: file(sourceInstanceName: { eq: "product" }, name: { eq: "newyork" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          },
        }
      },
      portugal: file(sourceInstanceName: { eq: "product" }, name: { eq: "portugal" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      yorkton: file(sourceInstanceName: { eq: "product" }, name: { eq: "yorkton" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      toronto: file(sourceInstanceName: { eq: "product" }, name: { eq: "toronto" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      buenos: file(sourceInstanceName: { eq: "product" }, name: { eq: "BuenosAires" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      commffest: file(sourceInstanceName: { eq: "product" }, name: { eq: "Commffest" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mexico: file(sourceInstanceName: { eq: "product" }, name: { eq: "Mexico" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      milano: file(sourceInstanceName: { eq: "product" }, name: { eq: "Milano" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      lunenberg: file(sourceInstanceName: { eq: "product" }, name: { eq: "lunenberg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
  <StyledSection id="trailer">
    <StyledContainer>
      <Subtitle>View the Trailer</Subtitle>
      <Trailer>
        <iframe src="https://player.vimeo.com/video/382064733" width="640" height="360" frameBorder="0"
                allow="autoplay; fullscreen" allowFullScreen title="Magical Imperfection trailer"></iframe>
      </Trailer>
      <LaurelContainer>
        <div className='img-container'>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.faff.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.newYork.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.portugal.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.toronto.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.yorkton.childImageSharp.fluid}/>
          </ImageWrapper>
        </div>
        <div className='img-container'>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.buenos.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.milano.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.mexico.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.commffest.childImageSharp.fluid}/>
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage loading='eager' fluid={data.lunenberg.childImageSharp.fluid}/>
          </ImageWrapper>
        </div>
      </LaurelContainer>
    </StyledContainer>
  </StyledSection>
)
}

export default Laurels

const StyledContainer = styled(Container)``
const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
`

const LaurelContainer = styled.div`
  // color: ${props => props.theme.color.primary};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 40px auto 40px;
  
  .img-container {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 0 auto;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 150px;
  margin: 16px 0;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 150px;
  }
`

const Subtitle = styled.h5`
  font-size: 26px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 32px;
  text-align: center;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 8px;
  }
`

const Trailer = styled.div`
  display: flex;
  justify-content: center;
`
